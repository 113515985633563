<template>
  <div class="my_container table">
    <v-data-table
        :headers="headers"
        :items="desserts"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="elevation-1"
        disable-sort
        :loading="loading"
    >
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
          v-model="page"
          :length="pageCount"
          @input="change_page(page)"
          total-visible="10"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import {user_data} from "@/api/admin";

export default {
  data () {
    return {
      loading: true,
      page_loading: true,
      page: 1,
      itemsPerPage: null,
      pageCount: null,
      headers: [
        {
          text: 'First Name',
          align: 'start',
          value: 'first_name',
        },
        { text: 'Email', value: 'email',align: 'start', },
        { text: 'Country ', value: 'country',align: 'start', },
        { text: 'Phone ', value: 'phone',align: 'start', },
        { text: 'Page', value: 'page',align: 'start', },
        { text: 'Age', value: 'age',align: 'start', },
        { text: 'User Ip (%)', value: 'user_ip',align: 'start'},
        { text: 'Traffic Source', value: 'traffic_source',align: 'start', },
        { text: 'Created_at', value: 'created_at',align: 'start', },
      ],
      desserts: [],
    }
  },
  methods: {
    change_page(page) {
      this.loading = true
      this.desserts = []
      user_data({page: page}).then(res => {
        this.itemsPerPage = res.users.per_page;
        this.pageCount = res.users.last_page;
        this.page = res.users.current_page;
        this.desserts = res.users.data;
      })
      .catch(() => {});
    }
  },
  mounted() {
    this.change_page(this.page)
  }
}
</script>

<style scoped>

</style>
